
import {Component, Mixins, Ref} from "vue-property-decorator";
import GenelIslemFormWizard, {GenelIslemEntity} from "@/components/forms/form-wizard/eicrapro/GenelIslemFormWizard.vue";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";
import {IExcelDosya, Task} from "@/plugins/uyap-plugin/store/modules/GorevYoneticisiTypes";
import {TaskTuru} from "@/plugins/uyap-plugin/enum/TaskTuru";
import PersonelPicker from "@/components/pickers/PersonelPicker.vue";
import {VForm} from "@/types";
import TakipSorguAlanlariPicker from "@/components/pickers/TakipSorguAlanlariPicker.vue";
import {TakipSorgulaTaskDataInterface} from "@/plugins/uyap-plugin/store/modules/TaskDataInterface/TakipSorgulaTaskDataInterface";
import {ProgramPaths} from "@/enum/ProgramPaths";
import {IAvukatDosya} from "@/plugins/uyap-plugin/uyap/CommonInterfaces";

@Component({
  components: {TakipSorguAlanlariPicker, PersonelPicker, GenelIslemFormWizard}
})
export default class TakipSorgulaFormWizard extends Mixins(SimpleInputMixin) {
  @Ref('form') readonly form!: VForm;
  @Ref('genel-form') genelForm!: GenelIslemFormWizard;

  localValue: GenelIslemEntity = new GenelIslemEntity();
  valid = false;
  onFly = false;

  stepperHeaders = [
    "Listelenecek Takipler",
    "Takipleri Seç",
    "Sorgulanacak Alanları Seç",
    "İşlemler Sıraya Alındı"
  ];

  islemDegerleri: TakipSorgulaTaskDataInterface = {
    sorguAlanlari: [],
    icraproyaAktar: false
  }

  async createTask() {
    if (this.form.validate()) {
      this.onFly = true;
      let tasks: Array<Task> = [];
      // @ts-ignore
      this.localValue.secilen_takipler.forEach((secilenTakip: IAvukatDosya | IExcelDosya) => {
        let task: Task = new Task()
        task.dosya_esas_no = secilenTakip.dosyaNo;
        task.birim_adi = secilenTakip.birimAdi;
        task.birim_id = secilenTakip?.birimId;
        task.uyap_dosya_acilis_tarihi = secilenTakip.dosyaAcilisTarihi ? new Date(this.$uyapHelper.formatDate(secilenTakip.dosyaAcilisTarihi)).toString() : "";
        task.dosya_id = secilenTakip.dosyaId;
        task.task_type_id = TaskTuru.takip_sorgula;
        task.data = this.islemDegerleri;
        tasks.push(task);
      });
      await this.$store.dispatch('postTasks', tasks);
      await this.$router.push(ProgramPaths.eicrapro + '/islemler');
      this.genelForm.activeStep++;
      this.onFly = false;
    }
  }
}
